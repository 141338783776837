import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import headshot from '../img/headshot.jpg';
import Services from '../components/Services';

export const IndexPageTemplate = ({ image }) => (
  <div>
    <div
      className="flex justify-center align-center h-64"
      style={{
        backgroundImage: `url(${
          image?.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `50% 53%`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div className="flex flex-col justify-center items-start">
        <h1 className="c-h1 bg-blue-700 text-white px-4 py-4 mb-4 text-center self-stretch">
          Island Investors
        </h1>
      </div>
    </div>
    <Services teaser />
    <Services />
    <div
      id="contact"
      className="py-16 flex flex-row justify-center bg-blue-200 bg-fixed"
    >
      <div className="shadow-2xl bg-white flex flex-row">
        <div>
          <h4 className="c-h4 bg-blue-500 py-2 px-2 text-white">
            Randy Wright
          </h4>
          <h5 className="c-h5 px-2 mt-2">Managing partner</h5>
          <ul className="list-disc list-inside p-4">
            <li>Founder of Island Investors and Island Consulting</li>
            <li>Senior Associate, CBRE</li>
            <li>Associate, Jones Lang LaSalle</li>
            <li>Associate, TriMont Real Estate</li>
          </ul>
        </div>
      </div>
      <img
        src={headshot}
        alt="Randy Wright"
        className="md:w-1/6 w-1/3 object-contain"
      />
    </div>
    <div className="py-16 flex flex-row justify-center bg-blue-200 bg-fixed">
      <span>
        Contact us at
        {'  '}
        <a
          className="underline hover:font-bold"
          href="mailto:inquiry@islandinv.com"
        >
          {' '}
          Inquiry@IslandInv.com
        </a>
      </span>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

IndexPageTemplate.defaultProps = {
  image: null,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate image={frontmatter.image} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
