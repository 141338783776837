import React from 'react';
import PropTypes from 'prop-types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useStaticQuery, graphql } from 'gatsby';
import Service from './Service';

const Services = ({ teaser }) => {
  const data = useStaticQuery(graphql`
    query ServicesRemark {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "service" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              templateKey
              shortDescription
              featuredimage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
            html
          }
        }
      }
    }
  `);
  if (teaser) {
    return (
      <div className="flex flex-wrap lg:flex-no-wrap flex-row m-4 justify-center shadow-md">
        {data.allMarkdownRemark.edges.map(node => {
          const {
            id,
            frontmatter: { title, shortDescription },
            html,
          } = node.node;
          return (
            <Service
              id={id}
              title={title}
              html={html}
              teaser={teaser}
              shortDesc={shortDescription}
            />
          );
        })}
      </div>
    );
  }
  return (
    <>
      <div>
        {data.allMarkdownRemark.edges.map(node => {
          const {
            id,
            frontmatter: { title, shortDescription, featuredimage },
            html,
          } = node.node;
          console.log(node.node.frontmatter);
          const fluidImage = featuredimage.publicURL;
          console.log(fluidImage);
          return (
            <Service
              id={id}
              title={title}
              html={html}
              teaser={teaser}
              shortDesc={shortDescription}
              image={fluidImage}
            />
          );
        })}
      </div>
    </>
  );
};

Services.propTypes = {
  data: PropTypes.shape({
    edges: PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
        }).isRequired,
        html: PropTypes.node.isRequired,
      }),
    }),
  }),
  teaser: PropTypes.bool,
};

Services.defaultProps = {
  teaser: false,
};

export default Services;
