import React from 'react';
import PropTypes from 'prop-types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Img from 'gatsby-image';

import Content, { HTMLContent } from './Content';

const Service = ({ title, id, html, teaser, shortDesc, image }) => {
  if (teaser) {
    return (
      <div className="flex-col flex items-center lg:items-start w-full max-w-lg py-5 m-4 border-b-2 lg:border-b-0 ">
        <h2 className="c-h3 mb-3">{title}</h2>
        <Content content={shortDesc} />
        <AnchorLink
          className="button"
          to={`/#${id}`}
          title={`More about ${title}`}
        />
      </div>
    );
  }
  return (
    <div id={id} className="flex flex-row items-stretch min-h-screen flex-wrap">
      <div className="w-full md:w-1/2 p-12 h-auto flex items-center">
        {image && <img srcSet={image} />}
      </div>
      <HTMLContent
        content={html}
        className="w-full md:w-1/2 bg-white flex flex-col justify-center p-12"
      />
    </div>
  );
};

Service.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  html: PropTypes.node.isRequired,
  teaser: PropTypes.bool.isRequired,
};

export default Service;
